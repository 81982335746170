import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

 /**
   * Takes a value and makes it lowercase.
   */
  transform(value: string) {
    if(!value) return 'NO_TIME';
    return this.timeDifference(new Date(), new Date(value));
  }

  /**
   * @param  {date} current  
   * @param  {date} previous 
   * @return {string}       
   */
  timeDifference(current, previous):string {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
      let n = current.toString()
    let x = n.slice(0,-3)
    current = parseInt(x)
    var elapsed =  current - previous;
  
    // console.log('curre',current);
    // console.log('perr',previous);
    
    if (elapsed < msPerMinute) {
      console.log(elapsed,msPerMinute);
      
      if(Math.round(elapsed/1000)<3) return 'Baru saja';
      return Math.round(elapsed/1000) + ' detik lalu';
    }

    else if (elapsed < msPerHour) {
      return Math.round(elapsed/msPerMinute) + ' menit lalu';
    }

    else if (elapsed < msPerDay ) {     
      return Math.round(elapsed/msPerHour ) + ' jam lalu';
    }


    else if (elapsed < msPerMonth) {
       if(Math.round(elapsed/msPerDay ) ==1)return 'Kemarin ';
       return Math.round(elapsed/msPerDay) + ' hari lalu';
    }

    else if (elapsed < msPerYear) {
      return 'about ' + Math.round(elapsed/msPerMonth) + ' bulan lalu';
    }

    else {
      return this.dateFormat(previous);
    }
  }

dateFormat(value):string{
        var datePipe = new DatePipe("en-US");
        value = datePipe.transform(value, 'HH:mm');      
         return value;
        }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'scan-qr',
    loadChildren: () => import('./scan-qr/scan-qr.module').then( m => m.ScanQrPageModule)
  },
  {
    path: 'detail-contact',
    loadChildren: () => import('./pages/detail-contact/detail-contact.module').then( m => m.DetailContactPageModule)
  },
  {
    path: 'web',
    loadChildren: () => import('./pages/layout/layout.module').then( m => m.LayoutPageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./pages/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'list/:name/:num',
    loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'empty',
    loadChildren: () => import('./pages/empty/empty.module').then( m => m.EmptyPageModule)
  },
  {
    path: 'image-review',
    loadChildren: () => import('./pages/image-review/image-review.module').then( m => m.ImageReviewPageModule)
  },
  {
    path: 'new-message',
    loadChildren: () => import('./pages/new-message/new-message.module').then( m => m.NewMessagePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login-web',
    loadChildren: () => import('./login-web/login-web.module').then( m => m.LoginWebPageModule)
  },
  {
    path: 'modal-new-chat',
    loadChildren: () => import('./pages/modal-new-chat/modal-new-chat.module').then( m => m.ModalNewChatPageModule)
  },
  {
    path: 'modal-show-media',
    loadChildren: () => import('./pages/modal-show-media/modal-show-media.module').then( m => m.ModalShowMediaPageModule)
  },
  {
    path: 'modal-convert-closing',
    loadChildren: () => import('./pages/modal-convert-closing/modal-convert-closing.module').then( m => m.ModalConvertClosingPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

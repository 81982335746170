import { enableProdMode, Injectable } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { HammerGestureConfig } from '@angular/platform-browser'

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

@Injectable()
export class IonicGestureConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    let mc : any
    if (window) {
       mc = new (<any>window).Hammer(element)

      for (const eventName in this.overrides) {
        if (eventName) {
          mc.get(eventName).set(this.overrides[eventName])
        }
      }
    }

    return mc 
  }
}

import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Plugins, StatusBarStyle } from '@capacitor/core';

const { StatusBar } = Plugins;
const BREAKPOINT = 992;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public isMobile: boolean;
  isLogin: any;
  isScaned: any;
  constructor(
    private route: Router,
    private plt: Platform,
  ) { }

  ngOnInit() {
    this.isLogin = localStorage.getItem('islogin');
    this.isScaned = localStorage.getItem('isScaned');
    StatusBar.setOverlaysWebView({
      overlay: true,
    });
    console.log("is login", this.isLogin);
    if (this.isLogin == null || !this.isLogin) {
      this.route.navigateByUrl('/login', { replaceUrl: true })
    } else if (this.isLogin != null && this.isScaned == null) {
      this.route.navigateByUrl('/scan-qr', { replaceUrl: true })
    } else if(this.isLogin != null && this.isScaned !=null){
      const width = this.plt.width();
      this.isMobile = width < BREAKPOINT;
      console.log('is mobile first', this.isMobile);

      if (!this.isMobile) {
        this.route.navigateByUrl('/web', { replaceUrl: true })
      } else {
        this.route.navigateByUrl('/list', { replaceUrl: true })
      }
    }


  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    const newWidth = event.target.innerWidth;
    this.isLogin = localStorage.getItem('islogin');
    this.isScaned = localStorage.getItem('isScaned');
    console.log("new width", newWidth);
    console.log("is login", this.isLogin);
    console.log("is mobile", this.isMobile);
    if (this.isMobile && newWidth >= BREAKPOINT) {
      if (this.isLogin == null) {
        this.route.navigateByUrl('/login-web', { replaceUrl: true });
      }  else if(this.isLogin != null && this.isScaned == null){
        this.route.navigateByUrl('/scan-qr', { replaceUrl: true })
      }else if(this.isLogin !=null && this.isScaned != null){
        this.route.navigateByUrl('/web', { replaceUrl: true })
      }

    } else if (!this.isMobile && newWidth < BREAKPOINT) {
      if (this.isLogin == null) {
        this.route.navigateByUrl('/login', { replaceUrl: true })
      } else if(this.isLogin != null && this.isScaned == null){
        this.route.navigateByUrl('/scan-qr', { replaceUrl: true })
      }else if(this.isLogin !=null && this.isScaned != null){
        this.route.navigateByUrl('/list', { replaceUrl: true })
      }

    }
    this.isMobile = newWidth < BREAKPOINT;

  }
}

import { NgModule, Injectable } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { RelativeTimePipe } from './relative-time.pipe';
import { Contacts } from '@ionic-native/contacts/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { Toast } from '@ionic-native/toast/ngx';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // I will only use the swap gesture so 
    // I will deactivate the others to avoid overlaps
    'pinch': { enable: false },
    'rotate': { enable: false }
  }
}

@NgModule({
  declarations: [AppComponent,RelativeTimePipe],
  entryComponents: [],
  imports: [
    BrowserModule, 
    HammerModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule],
  providers: [
    { 
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    HttpClientModule,
    Contacts,
    Clipboard,
    RelativeTimePipe,
    Toast,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
